<template>
    <!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10326')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
    <!-- // nav -->
    <div id="container" class="container" v-if="currentInquiry !== undefined">
		<div id="sub" class="sub mypage">
            <!-- content -->
			<section class="content">
                <div class="box">
                    <div class="bbs">
                        <div class="bbs__detail">
                            <div class="bbs__info">
                                <span class="bbs__tag">
                                    {{currentInquiry.QUES_CD}}
                                </span>
                                <p class="bbs__title">
                                    {{currentInquiry.QUES_TITLE}}
                                </p>
                                <span class="bbs__date">
                                    {{dayjs(currentInquiry.DATE).format('YYYY.MM.DD HH:mm')}}
                                </span>
	                            <!-- [D] 접수중 일때 ing, 답변완료 일때 eng 클래스 추가-->
                                <span class="bbs__status" :class="currentInquiry.STATUS === '접수중' ? 'ing' : 'eng'"><!-- 재번역 체크 -->
                                    {{currentInquiry.STATUS}}
                                </span>
                            </div>
                            <div class="bbs__body">
                                <div class="bbs__content">
                                    {{currentInquiry.QUES_DESC}}
                                </div>
                                <div class="bbs__content bbs__content--answer">
                                    <strong>{{t('10327')}}</strong>
                                    <template v-if="currentInquiry.STATUS === '접수중'"><!-- 재번역 체크 -->
                                      {{t('10328')}}
                                    </template>
                                    <template v-else>
                                        {{currentInquiry.ANS_DESC}}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</section>
		</div>
	</div>
    <div id="navigation" class="navigation navigation--noborder" v-if="currentInquiry !== undefined">
        <!-- 접수중 일 때 -->
        <div class="btns btns--centered" v-if="currentInquiry.STATUS === '접수중'"><!-- 재번역 체크 -->
            <button type="button" class="btn btn-primary normal" @click="onClickEditInquiry"> {{t('10329')}} </button>
            <button type="button" class="btn btn-primary normal" @click="onClickRemoveInquiry"> {{t('10330')}} </button>
        </div>
        <!-- 답변완료 일 때 -->
        <div class="btns btns--centered" v-else>
            <button type="button" class="btn btn-primary normal" @click="onClickGoInquiryList"> {{t('10331')}} </button>
        </div>
	</div>
    <TheNavigation></TheNavigation>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted } from "vue"
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	setup: function() {
		const router = useRouter();
		const route = useRoute();
		const store = useStore();

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);

        const seq = Number(route.params.seq);

		const mmy0112 = computed(() => store.state.mmy01.mmy0112);
		const currentInquiry = computed(() => mmy0112.value.find(item => item.SEQ === seq));

		onMounted(()=>{
			if(isLogin.value && currentInquiry.value === undefined){
				store.dispatch("mmy01/fetchMmy0112", {
					proc_cd: "01", // 01: 조회, 09: seq 문의 삭제
					mem_id: userData.value.mem_token
				});
			}
		});

        const onClickRemoveInquiry = () => {
            if(confirm("정말 삭제하시겠습니까?") === true){
                store.dispatch("mmy01/fetchMmy011209", {
                    proc_cd: "09", // 01: 조회, 09: seq 문의 삭제
                    mem_id: userData.value.mem_token,
                    seq: seq
                }).then(() => {
			        router.replace("/mypage/inquiry/list");
                });
            }
        };

        const onClickEditInquiry = () => {
            router.push(`/mypage/inquiry/write/${seq}`);
        };

        const onClickGoInquiryList = () => {
			router.go(-1);
        };

		const goBack = () => {
			router.go(-1);
		};
        const { t }= useI18n() //번역필수 모듈

		return {
			goBack,
			dayjs,
            currentInquiry,
            t,  //번역필수 모듈
		  i18n,

            onClickRemoveInquiry,
            onClickEditInquiry,
            onClickGoInquiryList,
		}
	}
}
</script>